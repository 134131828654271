function add_smooth_move_handler() {
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
                &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top - 89
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        }
                        ;
                    });
                }
            }
        });
}


function init_cookie_handler() {
    let klickerr_cookie_use = Cookies.get('jrit_cookie_use');
    if (typeof klickerr_cookie_use === 'undefined') {
        setTimeout(function () {
            $('#cookie-hinweis').show("slide", {"direction": "down"}, "swing", 5000);
        }, 1000);
    }
    $('#cookie-accept').on('click', function () {
        Cookies.set('jrit_cookie_use', 'active', {expires: 365});
        $('#cookie-hinweis').hide("slide", {"direction": "down"}, "swing", 5000);
        //$('#cookie-hinweis').hide();
    });
}

function checkGoogleMaps() {
    let gm_map_use = Cookies.get('gm_map_use');
    if (typeof gm_map_use !== 'undefined') {
        $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD2IH5qEc7tg7weK-QT5-IZrWkiBuLqT7s&callback=initMap");
    }
    $('#gm-activate').on('click', () => {
        $.getScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyD2IH5qEc7tg7weK-QT5-IZrWkiBuLqT7s&callback=initMap");
        Cookies.set('gm_map_use', 'active', {expires: 365});
    })
}

function init_form_button_handler() {
    $('form').find('button').on('click', function () {
        let hp_input = $(".bordered-form").find("input.form-gender");
        let valid_form_input = $('[name="name"]')[0].validity.valid
            && $('[name="email"]')[0].validity.valid && $('[name="message"]')[0].validity.valid;
        if (valid_form_input) {
            if (hp_input.val() === "") {
                hp_input.val("popocatepetl");
                console.log("No entry in gender")
            } else {
                console.log("Bot!!!");
            }
        }
    })
}

function remove_required() {
    $(".bordered-form").find("input.form-gender").removeAttr('required');
}


$(function () {
    add_smooth_move_handler();
    init_cookie_handler();
    init_form_button_handler();
    checkGoogleMaps();
});
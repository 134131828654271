function initMap() {
    var myLatlng = new google.maps.LatLng(48.260037, 16.398158);
    var mapOptions = {
        zoom: 15,
        center: myLatlng
    };
    var map = new google.maps.Map(document.getElementById("map"), mapOptions);

    var marker = new google.maps.Marker({
        position: myLatlng,
        title: "J.R. IT-Solutions/EDV"
    });

    // To add the marker to the map, call setMap();
    marker.setMap(map);
    map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    var contentString = '<div id="content" style="text-align:center; ma">'
        + '<p id="firstHeading" class="firstHeading">J.R. IT-Solutions/EDV e.U.</p>'
        + '<div id="bodyContent">'
        + '<p><p>Hermann-Bahr-Straße 18/4A</p>'
        + '<a href="tel: +43 681 104 266 76">'
        + '<p> ☎   +43 681 104 266 76 </p></a> </a>'
        + '</p>' + '</div>' + '</div>';


    var infowindow = new google.maps.InfoWindow({
        content : contentString,
        maxWidth: 500
    });
    infowindow.open(map, marker);
    google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
    });

}